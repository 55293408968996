<template>
  <div v-cloak>
    <!-- spinner -->
    <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>
    <div v-if="isLoading" class="text-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <b-container v-else fluid>
      <b-row>
        <b-col>
          <!-- Info about the order -->
          <b-card>
            <b-container fluid>
              <Status-Badge
                class="float-right"
                :status="order.statusName"
              ></Status-Badge>
              <h4><i class="fas fa-file-alt"></i> Order {{ order.orderNo }}</h4>
              <hr />
              <b-list-group>
                <b-list-group-item>
                  <i class="fas fa-building"></i> Project:
                  <strong>{{ order.projectName }}</strong>
                </b-list-group-item>
                <b-list-group-item>
                  <i class="fas fa-calendar"></i> Created:
                  <strong>{{ order.created | formatLongDateTime }}</strong>
                </b-list-group-item>
                <b-list-group-item
                  ><i class="fas fa-address-book mr-2"></i>
                  {{ order.orderedBy }}
                </b-list-group-item>
                <b-list-group-item>
                  <i class="fas fa-phone mr-2"></i>
                  <a :href="`tel:${order.phone}`">{{ order.phone }}</a>
                </b-list-group-item>
                <b-list-group-item>
                  <i class="fas fa-envelope-open-text mr-2"></i>
                  <a :href="`mailto:${order.mail}`">{{ order.mail }}</a>
                </b-list-group-item>
                <b-list-group-item v-if="order.notes">
                  <p><i class="fas fa-sticky-note"></i> Notes:</p>
                  <b-form-textarea
                    v-model="order.notes"
                    rows="3"
                    max-row="6"
                    readonly
                  ></b-form-textarea>
                </b-list-group-item>
              </b-list-group>
            </b-container>
          </b-card>
        </b-col>
        <b-col>
          <b-row>
            <b-col>
              <b-card>
                <b-container fluid>
                  <b-row>
                    <b-col>
                      <h4>
                        <i class="mr-1 fas fa-calendar-alt"></i> Delivery
                        details
                      </h4>
                      <hr />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-list-group>
                        <b-list-group-item>
                          <i class="fas fa-calendar mr-2"></i> Preferred
                          delivery:
                          <strong>{{ preferredDeliveryDate }} </strong>
                        </b-list-group-item>
                        <b-list-group-item>
                          <i class="fas fa-map-marker-alt mr-2"></i>
                          {{ order.deliveryAddress.streetName }}
                        </b-list-group-item>
                        <b-list-group-item>
                          <i class="fas fa-map mr-2"></i>
                          {{ order.deliveryAddress.postalCode }},
                          {{ order.deliveryAddress.city }}
                        </b-list-group-item>
                        <b-list-group-item>
                          <i class="fas fa-address-book mr-2"></i>
                          {{ order.contanctPersonOnSite }}
                        </b-list-group-item>
                        <b-list-group-item>
                          <i class="fas fa-phone mr-2"></i>
                          <a :href="`tel:${order.phone}`">{{
                            order.contactPersonOnSitePhone
                          }}</a>
                        </b-list-group-item>
                        <b-list-group-item
                          v-if="order.contactPersonOnSiteComments"
                        >
                          Comments:
                          <p>{{ order.contactPersonOnSiteComments }}</p>
                        </b-list-group-item>
                      </b-list-group>
                    </b-col>
                  </b-row>
                </b-container>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-alert
            v-cloak
            v-if="this.order.orderLines.length === 0"
            show
            variant="danger"
            >No order lines.</b-alert
          >
          <Order-Lines-Card :order="order" v-if="!showTable"></Order-Lines-Card>
          <Order-Lines-Table
            v-else
            :order="order"
            class="mt-2"
          ></Order-Lines-Table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import OrderLinesTable from "@/components/OrderLinesTable";
import OrderLinesCard from "@/components/OrderLinesCard";
import StatusBadge from "@/components/StatusBadge";
import moment from "moment";

export default {
  name: "ordersharedview",
  components: {
    OrderLinesTable,
    OrderLinesCard,
    StatusBadge
  },
  data() {
    return {
      isLoading: true,
      error: null,
      order: {
        orderNo: -99,
        deliveryAddress: {
          streetName: ""
        },
        orderLines: []
      },
      showTable: true
    };
  },
  created() {
    window.scrollTo(0, 0);
    var self = this;
    var id = this.$route.params.id || null;
    if (id) {
      this.loadOrder(id)
        .then(r => {
          self.order = r.data;
          self.isLoading = false;
        })
        .catch(error => {
          this.error = error;
        });
    }
  },
  methods: {
    toggleTable() {
      this.showTable = !this.showTable;
    },
    ...mapActions(["loadOrder"])
  },
  computed: {
    ...mapGetters(["getCurrentOrder"]),
    statusNo: {
      get() {
        return this.getCurrentOrder.statusNo;
      }
    },
    description: {
      get() {
        return this.$store.state.orders.order.description || "";
      },
      set(value) {
        this.$store.commit("setOrderDescription", value);
      }
    },
    preferredDeliveryDate: {
      get() {
        return moment(String(this.order.preferredDeliveryDate)).format(
          "DD/MM/YYYY"
        );
      },
      set(value) {
        this.order.preferredDeliveryDate = value;
      }
    }
  }
};
</script>

<style></style>
