<template>
  <b-badge :variant="badgevariant">{{ status || "missing" }}</b-badge>
</template>

<script>
export default {
  name: "StatusBadge",
  props: ["status"],
  computed: {
    badgevariant: {
      get() {
        if (this.$props.status === "New") {
          return "success";
        } else if (this.$props.status === "Draft") {
          return "secondary";
        } else if (
          this.$props.status === "Delivired" ||
          this.$props.status === "In process"
        ) {
          return "primary";
        } else if (this.$props.status === "Sendt") {
          return "info";
        } else if (this.$props.status === "Closed") {
          return "dark";
        } else if (
          this.$props.status === "Error" ||
          this.$props.status === "Delete"
        ) {
          return "danger";
        } else {
          return "light";
        }
      }
    }
  }
};
</script>

<style></style>
